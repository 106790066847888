.Chart {
  background-color: black;
  box-shadow: 0 2px 12px #000000;
}

.LoadingBackdrop {
  width: calc(100% - 8px);
  height: calc(100% - 50px - 23px - 4px - 2px); /* minus header, footer, padding and chart shadow */
  top: calc(50px + 4px);
  left: calc(4px);
}
