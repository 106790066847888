/* MOBILE */

.Modal {
  position: fixed;
  z-index: 200;
  background-color: #2d4059;
  width: 100%; /* changed */
  height: 75%; /* changed */
  border: 1px solid #2d4059;
  border-radius: 10px 10px 0 0; /* changed */
  box-shadow: 1px 1px 10px black;
  padding: 16px;
  left: calc(50% - 50%); /* changed */
  top: calc(100% - 75%); /* changed */
  box-sizing: border-box;
  text-align: center;
  transition: all 0.2s ease-out;
  transform: translateY(100vh);
  opacity: 0;
}
.Modal.show {
  transform: translateY(0);
  opacity: 1;
}

/* PC */

@media (min-width: 600px) and (max-width: 799px) {
  .Modal {
    width: 340px;
    height: 424px;
    border-radius: 5px;
    left: calc(50% - 170px);
    top: calc(50% - 212px);
    transform: translateY(-100vh);
    opacity: 0;
  }
  .Modal.show {
    transform: translateY(0);
    opacity: 1;
  }
}

@media (min-width: 800px) and (max-width: 999px) {
  .Modal {
    width: 380px;
    height: auto;
    padding-bottom: 50px;
    border-radius: 5px;
    left: calc(50% - 190px);
    top: calc(50% - 260px);
    transform: translateY(-100vh);
    opacity: 0;
  }
  .Modal.show {
    transform: translateY(0);
    opacity: 1;
  }
}

@media (min-width: 1000px) {
  .Modal {
    width: 400px;
    height: auto;
    padding-bottom: 50px;
    border-radius: 5px;
    left: calc(50% - 200px);
    top: calc(50% - 260px);
    transform: translateY(-100vh);
    opacity: 0;
  }
  .Modal.show {
    transform: translateY(0);
    opacity: 1;
  }
}
