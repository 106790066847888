.ErrorModal {
  position: fixed;
  z-index: 200;
  width: 400px;
  height: auto;
  left: calc(50% - 200px);
  top: calc(50% - 200px);
  box-sizing: border-box;
  text-align: center;
  padding: 30px 0;
  background-color: #0e1c31;
  border: 1px solid #0e1c31;
  border-radius: 5px;
  visibility: hidden;
  opacity: 0;
  box-shadow: 0 3px 6px black;
  transition: all 0.1s linear;
}

.ErrorModal.show {
  visibility: visible;
  opacity: 1;
}

.ErrorModal p {
  color: #c8c8c8;
  font-size: 16px;
  margin: 0 25px;
  line-height: 1.6;
}

.ErrorModal button {
  appearance: none;
  font-family: inherit;
  font-weight: bold;
  font-size: 15px;
  background-color: rgba(0, 159, 158, 0.7);
  color: #c8c8c8;
  margin-top: 25px;
  margin-bottom: 10px;
  padding: 0 10px;
  height: 41px;
  width: 30%;
  border-radius: 5px;
  box-shadow: 0 2px 4px #000000;
  cursor: default;
  border: none;
  outline: none;
  transition: all 0.1s ease-in;
}

.ErrorModal button:hover {
  box-shadow: none;
  background-color: rgba(0, 159, 158, 0.9);
}
