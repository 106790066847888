/* Mobile */

.SideDrawer {
  position: absolute;
  z-index: 150;
  top: 72px;
  left: 0;
  list-style: none;
  display: flex;
  flex-flow: column;
  margin: 0;
  padding: 2% 0 0 5.5%;
  height: calc(100% - 72px - 10px);
  width: 60%;
  background-color: #264467;
  box-shadow: 0 2px 2px black;
  transition: 0.2s ease-out;
}
.SideDrawer.Chart {
  top: 60px;
  height: calc(100% - 60px - 10px);
}

.SideDrawer.hide {
  transform: translateX(-100%);
  opacity: 0;
}

.Backdrop {
  top: 72px;
}
.Backdrop.Chart {
  top: 60px;
}

.MobileNav {
  padding-top: 0;
  padding-bottom: 7px;
  margin-right: 9%;
  margin-bottom: 18px;
  border-bottom: 1px solid #6f6f6f;
}

.MobileNav a {
  text-decoration: none;
  font-size: 20px;
  color: #fbfbfb;
  padding-right: 75%;
}
