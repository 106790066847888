/* MOBILE */

.FormTitle {
  font-family: inherit;
  font-size: 26px;
  font-weight: bold;
  margin: 20px 0 30px 0;
  color: white;
}

.Input {
  font-family: inherit;
  background-color: white;
  border: 1px solid black;
  border-radius: 5px;
  margin: 15px 7%;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.InputElement {
  font-size: 18px;
  font-family: inherit;
  display: flex;
  border: none;
  outline: none;
  width: 100%;
}

.UserLogo {
  height: 25px;
  margin-left: 1px;
  margin-right: 12px;
  display: flex;
}

.LockLogo {
  height: 30px;
  margin-right: 10px;
  display: flex;
}

.LoginLogo {
  height: 90px;
  margin-top: -62px;
}

.AuthModal button {
  font-family: inherit;
  font-weight: bold;
  font-size: 24px;
  background-color: #01c5c4;
  color: white;
  margin-top: 10px;
  padding: 0 10px;
  height: 60px;
  width: 86%;
  border-radius: 5px;
  box-shadow: 0 1px 4px #000000;
  cursor: pointer;
  border: none;
  outline: none;
  transition: all 0.1s ease-in;
}

.AuthModal button:active {
  box-shadow: none;
  transform: translateY(0.05em);
}
