/* Mobile */
.Home {
  align-items: center;
  text-align: center;
  color: white;
  width: 80%; /* changed */
  height: 300px;
  margin: 50px auto auto auto;
  background-color: #112d4e;
  position: relative;
  box-shadow: 0 1px 5px #000000;
  border-radius: 10px;
}

.Content {
  position: absolute;
  width: 100%;
  top: 46%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.Content p {
  margin: 0;
  padding-bottom: 25px;
  font-weight: bold;
  font-size: 24px; /* changed */
  width: 100%;
}

.Content button {
  font-family: inherit;
  font-weight: bold;
  font-size: 20px; /* changed */
  background-color: #01c5c4;
  color: white;
  margin-top: 20px;
  padding-bottom: 3.5px;
  height: 50px; /* changed */
  width: 150px; /* changed */
  border-radius: 15px;
  box-shadow: 0 1px 8px #000000;
  cursor: pointer;
  border: none;
  outline: none;
  transition: all 0.15s ease-in-out;
}

.Content button:hover {
  box-shadow: 0 1px 4px #01c5c4;
  transform: translateY(-0.15em);
}

/* Tablet & PC */

@media (min-width: 600px) {
  .Home {
    width: 70%;
  }

  .Content p {
    font-size: 30px;
  }

  .Content button {
    font-size: 24px;
    height: 60px;
    width: 190px;
  }

  .Content button:hover {
    box-shadow: 0 1px 4px #01c5c4;
    transform: translateY(-0.15em);
    /* box-shadow: 0 0.5em 0.5em -0.4em var(--hover); */
  }
}
