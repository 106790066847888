.Backdrop {
  opacity: 0;
}

.DropDown {
  position: absolute;
  z-index: 150;
  top: 32px;
  left: -115px;
  transition: all 0.1s linear;
  visibility: hidden;
  opacity: 0;
  display: flex;
  flex-direction: column;
}

.DropDown.show {
  visibility: visible;
  opacity: 1;
}

.CurrentTimeframe {
  position: absolute;
  top: -17px;
  left: -115px;
  text-decoration: none;
  -moz-appearance: none;
  border: none;
  align-items: center;
  margin: auto 2.5px;
  padding-top: 2px;
  height: 34px;
  width: 45px;
  color: #dfdfdf;
  background-color: #0e1c31;
  box-shadow: 0 1px 2px black;
  border-radius: 1px;
  outline: none;
  transition: 0.1s ease-in-out;
}

.Timeframe {
  text-decoration: none;
  -moz-appearance: none;
  border: none;
  align-items: center;
  margin: auto 2.5px;
  padding-top: 2px;
  height: 34px;
  width: 45px;
  color: #dfdfdf;
  background-color: #0e1c31;
  box-shadow: 0 1px 2px black;
  border-radius: 1px;
  outline: none;
  transition: 0.1s ease-in-out;
}

.Timeframe.Active {
  background-color: #00b1b0;
  color: white;
}
