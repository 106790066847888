/* Mobile */

.Content {
  background-color: #132743;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 5;
  padding: 125px 5% 42.75px;
  box-sizing: border-box;
  align-items: center;
}

.Chart {
  padding: 125px 4px 42.75px;
}

.Mobile {
  padding: 60px 0 2px;
}

/* Tablet */

@media (min-width: 600px) and (max-width: 699px) {
  .Content {
    padding: 125px 5% 42.75px;
  }

  .Chart {
    padding: 55px 4px 25px;
  }
}

/* PC */

@media (min-width: 700px) {
  .Content {
    padding: 125px 10% 42.75px;
  }

  .Chart {
    padding: 55px 4px 25px;
  }
}
