/* Mobile */

.Footer {
  font-size: 12px;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  text-align: center;
  width: 100%;
  padding: 9px 0;
  position: absolute;
  bottom: 0;
  z-index: 20;
  color: #dfdfdf;
  background-color: #081f37;
}

.Footer a {
  text-decoration: none;
  color: #dfdfdf;
}

.Footer.ChartFooter {
  font-size: 10px;
  padding: 4px 0 4px;
  color: rgba(223, 223, 223, 0.8);
}

.Footer.ChartFooter a {
  color: rgba(223, 223, 223, 0.8);
}

/* Tablet & PC */

@media (min-width: 600px) {
  .Footer {
    font-size: 14px;
    padding: 12px 0;
  }

  .Footer.ChartFooter {
    font-size: 11px;
  }
}
