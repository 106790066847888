.Tooltip {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  font-size: 13px;
  color: #dfdfdf;
  background-color: #0f3057;
  border-radius: 5%;
  transition: 0.3s opacity linear;
}

.Tooltip.show {
  visibility: visible;
  opacity: 0.8;
}

.Tooltip p {
  margin: 8px 10px;
}
