/* MOBILE */

.FormTitle {
  font-family: inherit;
  font-size: 32px;
  font-weight: bold;
  margin: 20px 0 30px 0;
  color: white;
}

.Input {
  font-family: inherit;
  background-color: white;
  border: 1px solid black;
  border-radius: 5px;
  margin: 15px 10px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.InputElement {
  font-size: 18px;
  font-family: inherit;
  display: flex;
  border: none;
  outline: none;
  width: 100%;
}
.InputElement:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.UserLogo {
  height: 25px;
  margin-left: 1px;
  margin-right: 12px;
  display: flex;
}

.LockLogo {
  height: 30px;
  margin-right: 10px;
  display: flex;
}

.LoginLogo {
  height: 90px;
  margin-top: -62px;
}

.SubmitButton, .HomeButton {
  font-family: inherit;
  font-weight: bold;
  font-size: 26px;
  background-color: #01c5c4;
  color: white;
  margin-top: 10px;
  padding: 0 10px;
  height: 60px;
  width: 94%;
  border-radius: 5px;
  box-shadow: 0 1px 4px #000000;
  cursor: pointer;
  border: none;
  outline: none;
  transition: all 0.1s ease-in;
}

.SubmitButton:active, .HomeButton:active {
  box-shadow: none;
  transform: translateY(0.05em);
}

/* PC */

@media (min-width: 600px) and (max-width: 799px) {
  .FormTitle {
    font-size: 26px;
    margin: 25px 0 30px 0;
  }

  .Input {
    margin: 13px 10px;
    padding: 8px;
  }

  .InputElement {
    font-size: 14px;
  }

  .UserLogo {
    height: 20px;
    margin-left: 1px;
    margin-right: 13px;
  }

  .LockLogo {
    height: 25px;
    margin-right: 10px;
  }

  .LoginLogo {
    height: 80px;
    margin-top: -57px;
  }

  .SubmitButton, .HomeButton {
    font-size: 20px;
    height: 50px;
  }
}

@media (min-width: 800px) {
  .FormTitle {
    font-size: 26px;
    margin: 25px 0 30px 0;
  }

  .InputElement {
    font-size: 15px;
  }

  .SubmitButton, .HomeButton {
    font-size: 20px;
    height: 50px;
  }
}
