/* Tablel & PC */

@media (min-width: 600px) {
  .NavigationItem {
    font-size: 20px;
    align-items: center;
    display: flex;
    height: 60px;
  }

  .NavigationItem a {
    color: white;
    text-decoration: none;
    padding: 0 12px;
    transition: all 0.15s ease-in-out;
  }

  .NavigationItem a:hover {
    text-shadow: 0 1px 3px lightgray;
  }
}
