.Loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #132743;
}

.Spinner {
  margin: 30vh auto 0;
  font-size: 4px;
}
