/* Mobile */

.DropDown {
  position: absolute;
  z-index: 20;
  top: 10px;
  right: 62px;
  width: 70px;
  height: 70px;
  padding: 10px 10px;
  border-radius: 3px;
  background-color: #dfdfdf;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 1px 5px black;
  transform: translateY(0);
  opacity: 1;
  transition: 0.2s ease-out;
}
.DropDown.hide {
  transform: translateY(-100px);
  opacity: 0;
}
.Link {
  margin-bottom: 5px;
  display: flex;
  border-bottom: 1px solid rgba(99, 112, 126, 0.5);
}
.DropDown a {
  font-size: 16px;
  font-weight: bold;
  color: #0f4c75;
  text-decoration: none;
  transition: 0.3s ease-out;
}
.DropDown a:hover {
  font-size: 17px;
  transform: translateY(-0.05em);
}
.Backdrop {
  position: absolute;
  z-index: 15;
  background-color: rgba(0, 0, 0, 0);
}

/* Tablet & PC */

@media (min-width: 600px) {
  .DropDown {
    position: absolute;
    z-index: 20;
    top: 10px;
    right: 62px;
    width: 70px;
    height: 70px;
    padding: 10px 10px;
    border-radius: 3px;
    background-color: #dfdfdf;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0 1px 5px black;
    transform: translateY(0);
    opacity: 1;
    transition: 0.2s ease-out;
  }
  .DropDown.hide {
    transform: translateY(-100px);
    opacity: 0;
  }
  .Link {
    margin-bottom: 5px;
    display: flex;
    border-bottom: 1px solid rgba(99, 112, 126, 0.5);
  }
  .DropDown a {
    font-size: 16px;
    font-weight: bold;
    color: #0f4c75;
    text-decoration: none;
    transition: 0.3s ease-out;
  }
  .DropDown a:hover {
    font-size: 17px;
    transform: translateY(-0.05em);
  }
  .Backdrop {
    position: absolute;
    z-index: 15;
    background-color: rgba(0, 0, 0, 0);
  }
}
