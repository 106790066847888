.Backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  background-color: black;
  opacity: 0;
  visibility: hidden;
  transition: all 0.1s linear;
}

.Backdrop.show {
  visibility: visible;
  opacity: 0.4;
}
