/* Mobile */
.homeNavItem {
  margin: 0;
  font-size: 28px;
  font-weight: bold;
}

.homeNavItem a {
  color: white;
  text-decoration: none;
  display: flex;
}

.homeNavItem img {
  height: 40px;
  display: flex;
  padding-right: 10px;
}
/* PC */

@media (min-width: 600px) {
  .homeNavItem {
    margin: 0;
    font-size: 36px;
    font-weight: bold;
  }

  .homeNavItem a {
    color: white;
    text-decoration: none;
    display: flex;
  }

  .homeNavItem img {
    height: 60px;
    display: flex;
    padding-right: 10px;
  }
}
