.Table {
  position: relative;
  cursor: default;
  width: 90%;
  /*border-spacing: 0;*/
  border-collapse: collapse;
}

.HeaderRow {
  height: 50px;
}

.DataRow:hover td {
  background: #576e89;
}

.Header {
  position: sticky;
  top: 0;
  width: 120px;
  background: #264467;
  color: #dfdfdf;
  font-size: 15px;
  font-weight: 400;
  cursor: pointer;
  padding: 10px;
}

.Header.symbol {
  width: 130px;
}

.HeaderChild {
  text-align: right;
  display: flex;
  justify-content: flex-end;
}

.HeaderChild.symbol {
  text-align: left;
  justify-content: flex-start;
}

.HeaderName {
  display: inline-block;
}

.FilterIcons {
  margin-left: 2px;
  display: inline-block;
}

.UpTriangle {
  font-size: 8px;
}
.Header.symbol .UpTriangle.Active,
.Header.price .UpTriangle.Active,
.Header.change24 .UpTriangle.Active,
.Header.trades24 .UpTriangle.Active,
.Header.volume24 .UpTriangle.Active{
  color: #53ebe7;
}

.DownTriangle {
  font-size: 8px;
}
.Header.symbol .DownTriangle.Active,
.Header.price .DownTriangle.Active,
.Header.change24 .DownTriangle.Active,
.Header.trades24 .DownTriangle.Active,
.Header.volume24 .DownTriangle.Active{
  color: #53ebe7;
}

.Cell {
  padding: 10px;
  background: #0e1c31;
  font-size: 15px;
  color: #cccccc;
  text-align: right;
  border-bottom: 2px solid rgba(89, 89, 89, 0.4);
}

.Cell.symbol {
  text-align: left;
}

.Cell.change24.Change24Up, .Cell.price.PriceUp {
  color: lightseagreen;
}

.Cell.change24.Change24Down, .Cell.price.PriceDown {
  color: orangered;
}

@media (max-width: 1030px) {
  .Table {
    width: 95%;
  }

  .Header {
    font-size: 12px;
    width: 200px;
  }

  .Header.symbol {
    width: 100px;
  }

  .Cell {
    font-size: 12px;
  }

  .FilterIcons {
    margin-top: 1px;
  }

  .UpTriangle {
    font-size: 6px;
  }

  .DownTriangle {
    font-size: 6px;
  }
}
