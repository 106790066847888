.Indicators {
  height: auto;
  width: 360px;
  position: absolute;
  background-color: #0e1c31;
  left: calc(50% + 86px + 102px);
  top: 54px;
  z-index: 20;
  padding: 5px 8px;
  visibility: hidden;
  opacity: 0;
  box-shadow: 0 3px 6px black;
  transition: all 0.05s linear;
}

.Indicators.show {
  visibility: visible;
  opacity: 0.9;
}

.Indicator {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 4px 0;
  border-bottom: 1px solid rgba(89, 89, 89, 0.4);
  cursor: default;
}
.Indicator:hover {
  border-color: rgba(0, 159, 158, 0.7);
}
.Indicator.Active {
  border-color: rgba(0, 159, 158, 0.7);
}
.Indicator.Active:hover {
  border-color: #66dcdb;
}
.Indicator:hover .IndicatorName {
  color: #01c5c4;
}
.Indicator.Active .IndicatorName {
  color: #01c5c4;
}
.Indicator.Active:hover .IndicatorName {
  color: #66dcdb;
}

.LastTopIndicator {
  border-bottom: 1px solid rgba(89, 89, 89, 1);
}

.IndicatorName {
  color: #dfdfdf;
  font-size: 13px;
  margin: auto auto auto 0;
}
.IndicatorName:hover {
  color: rgba(0, 159, 158, 0.7);
}

.Buttons {
  display: flex;
  justify-content: space-between;
  width: 75px;
}

.BaseQuoteButtons {
  display: flex;
  justify-content: space-between;
  width: 104px;
  padding-right: 8px;
}
.BaseQuoteButtons.Active {
  background-color: rgba(0, 159, 158, 0.5);
}
.BaseQuoteButtons.Active:hover {

}

.DiffButtons {
  display: flex;
  padding-right: 4px;
}

.Buttons img {
  height: 14px;
}

.Buttons button {
  width: 22px;
}

button {
  height: 22px;
  width: 50px;
  border: 1px solid #6f6f6f;
  background-color: #0e1c31;
  color: #dfdfdf;
  outline: none;
  padding: 2px 2px 1px;
  border-radius: 2px;
  box-shadow: 0 1px 2px black;
}
button:hover {
  background-color: rgba(0, 159, 158, 0.7);
  box-shadow: none;
}
button.Active {
  background-color: rgba(0, 159, 158, 0.5);
}
button.Active:hover {
  background-color: rgba(0, 159, 158, 1);
}

.ArrowDown {
  transform: rotate(180deg);
}

.Backdrop {
  z-index: 18;
  background-color: transparent;
}

@media (max-width: 1280px) {
  .Indicators {
    left: calc(50% - 180px);
  }
}
