/* Mobile */
.NavigationItems {
  list-style: none;
  display: flex;
  flex-flow: row;
  margin: 0;
  padding: 0;
}

.Chart {
  display: flex;
}

.Chart img {
  height: 30px;
  display: flex;
  padding-right: 5px;
}

.ChartName {
  display: flex;
  align-items: center;
}
/* Tablet & PC */

@media (min-width: 600px) {
  .NavigationItems {
    list-style: none;
    display: flex;
    flex-flow: row;
    margin: 0;
    padding: 0;
  }

  .Chart {
    display: flex;
  }

  .Chart img {
    height: 30px;
    display: flex;
    padding-right: 5px;
  }

  .ChartName {
    display: flex;
    align-items: center;
  }
}
