/* Mobile */

.Toolbar {
  width: 100%;
  position: absolute;
  z-index: 10;
  background-color: #0f3057;
  padding: 15px 5%;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
  color: #c8c8c8;
  align-items: center;
  box-shadow: 0 1px 10px #000000;
}
.ChartHeader {
  padding: 9px 16px;
  box-shadow: 0 1px 2px #000000;
}
.HomeLogo {
  /* Sent */
  height: 30px;
  cursor: pointer;
}
.HomeLogo img {
  /* Sent */
  height: 30px;
}

.Timeframes {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Timeframes.LeftTools {
  right: calc(50% + 86px + 50px);
}
.Timeframes.RightTools {
  left: calc(50% + 86px + 50px);
}

.Timeframe {
  display: inline-block;
  text-decoration: none;
  /* -webkit-appearance: none; */
  -moz-appearance: none;
  border: none;
  align-items: center;
  margin: auto 2.5px;
  height: 34px;
  width: 45px;
  color: #c8c8c8;
  background-color: #0e1c31;
  box-shadow: 0 1px 2px black;
  border-radius: 1px;
  outline: none;
  transition: 0.1s ease-in-out;
}
.Timeframe:focus {
  outline: none;
}
.Timeframe:hover {
  border-radius: 3px;
  background-color: #576e89;
}
.Timeframe:active {
  box-shadow: 0 1px 2px #264467;
}
.Timeframe.Active {
  background-color: #264467;
}
.Timeframe.Active:hover {
  background-color: #264467;
  box-shadow: 0 1px 2px black;
}
.Timeframe.Active:active {
  background-color: #264467;
  box-shadow: 0 1px 2px black;
}
.ChartReset {
  left: 160px;
}
.ChartReset.Mobile {
  left: 65px;
}

.Timeframe img {
  height: 20px;
  padding-top: 2px;
}
.Timeframe.Indicators img {
  height: 30px;
  padding-top: 1px;
}

.CoinName {
  width: 170px;
  z-index: 11;
  position: absolute;
  left: calc(50% - 86px);
  top: 5px;
  font-weight: bold;
  text-align: center;
  align-items: center;
  border: 1px solid #c8c8c8;
  border-radius: 2px;
  box-shadow: 0 2px 4px black;
  cursor: default;
}
.CoinName:hover {
  box-shadow: none;
  background-color: #0e1c31;
  border: 1px solid #b2b2b2;
  color: #dfdfdf;
}
.MobileCoinName {
  display: flex;
}
.CoinNameChild {
  margin: 8px 0;
  cursor: default;
}

.ChartMenuLogo {
  /* Sent */
  height: 22px;
}
.ChartMenuLogo img {
  /* Sent */
  height: 22px;
  cursor: pointer;
  transition: 0.2s ease-out;
}
.ChartMenuLogoRotate img {
  /* Sent */
  transform: rotate(90deg);
}
.MenuIcon {
  padding-top: 5px;
}
.MenuIcon img {
  height: 30px;
}
.ChartLogo img {
  height: 30px;
}
.Options {
  display: flex;
}
.Options img {
  height: 30px;
}

/* Tablet & PC */

@media (min-width: 600px) and (max-width: 699px) {
  .Toolbar {
    padding: 15px 10%;
  }
  .ChartHeader {
    padding: 10px 20px;
    box-shadow: 0 1px 2px #000000;
  }
  .HomeLogo {
    height: 30px;
    cursor: pointer;
  }
  .HomeLogo img {
    height: 30px;
  }
  .ChartMenuLogo {
    height: 22px;
  }
  .ChartMenuLogo img {
    height: 22px;
    transition: 0.2s ease-out;
  }
  .ChartMenuLogoRotate img {
    transform: rotate(90deg);
  }
}

@media (min-width: 700px) {
  .Toolbar {
    padding: 15px 10%;
  }
  .ChartHeader {
    padding: 10px 20px;
    box-shadow: 0 1px 2px #000000;
  }
  .HomeLogo {
    height: 30px;
    cursor: pointer;
  }
  .HomeLogo img {
    height: 30px;
  }
  .ChartMenuLogo {
    height: 22px;
  }
  .ChartMenuLogo img {
    height: 22px;
    transition: 0.2s ease-out;
  }
  .ChartMenuLogoRotate img {
    transform: rotate(90deg);
  }
}
