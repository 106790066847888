.DropDown {
  width: 900px;
  height: 500px;
  background-color: #0e1c31;
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 20;
  left: calc(50% - 450px);
  top: 53px;
  visibility: hidden;
  opacity: 0;
  color: #cccccc;
  box-shadow: 0 3px 6px black;
  transition: all 0.05s linear;
}

.DropDown.show {
  visibility: visible;
  opacity: 0.9;
}

.Symbols {
  margin: 0;
  padding: 0;
}

.Symbols.NotLoading {
  overflow-y: scroll;
}

.Symbols::-webkit-scrollbar {
  background-color: #3c4d5f;
  width: 5px;
}
.Symbols::-webkit-scrollbar-thumb {
  background: #abb6c4;
  border-radius: 4px;
}
.Symbols::-webkit-scrollbar-track {
  background-color: transparent;
}

.SymbolElement {
  display: flex;
  font-size: 16px;
  padding: 11px 27px;
  border-bottom: 2px solid rgba(89, 89, 89, 0.4);
  cursor: default;
}

.SymbolElement:hover {
  background-color: #576e89;
}

.SearchForm {
  padding: 10px 0;
  margin: 5px 0 5px 45px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  position: relative;
}

.SearchInput {
  width: 100%;
  height: 32px;
  font-size: 15px;
  background-color: #070e18;
  color: #dfdfdf;
  font-family: "Open Sans", sans-serif;
  border: 1px solid #b2b2b2;
  border-radius: 5px;
  padding: 1px 8px;
}

.SearchForm img {
  height: 20px;
  margin: auto 15px auto 15px;
}

.FilterOptions {
  position: absolute;
  z-index: 30;
  top: calc(36px + 10px);
  left: calc(100% - 64px);
  background-color: #070e18;
  visibility: hidden;
  opacity: 0;
}
.FilterOptions.show {
  visibility: visible;
  opacity: inherit;
}

.Filter {
  margin: 10px 6px;
  cursor: default;
  padding: 4px 4px;
}

.Filter.Active {
  background-color: #0f3057;
}

.Filter:hover {
  background-color: #576e89;
}

.Backdrop {
  z-index: 18;
  background-color: transparent;
}

.Spinner {
  font-size: 3.5px;
}

@media (max-width: 1030px) {
  .DropDown {
    width: 80%;
    left: calc(50% - 40%);
  }

  .SearchInput {
    font-size: 12px;
    height: 28px;
  }
}
