.Error404 {
  background-color: #132743;
  text-align: center;
  font-family: inherit;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  padding-top: 60px;
  box-sizing: border-box;
}

.Error404 p {
  color: #dfdfdf;
  font-size: 32px;
  font-weight: bold;
  margin: 0 auto 10px;
}

.Link {
  background-color: #01c5c4;
  height: 50px;
  width: 140px;
  align-items: center;
  cursor: pointer;
  padding-top: 7px;
  box-sizing: border-box;
  display: inline-block;
  margin-top: 40px;
  border-radius: 5px;
  box-shadow: 0 2px 8px black;
  transition: 0.3s ease-out;
}

.Link:hover {
  transform: translateY(-1px);
  box-shadow: 0 1px 3px #01c5c4;
}

.Link a {
  font-weight: bold;
  font-size: 24px;
  color: white;
  text-decoration: none;
  padding: 10px 34px;
}
